import { Typography } from '@mui/material'
import MyTagsLogo from 'components/svg-assets/MyTagsLogo'
import Link from 'next/link'
import styles from './PublicFooter.module.scss'

export default function PublicFooter ({ isAuthenticated }) {

  return (
    <footer className={styles.publicFooter}>
      <section className={`${styles.footerContent} ${styles.container}`}>
        <div className={styles.footerLeft}>
          <div className={styles.logo}>
            <MyTagsLogo />
          </div>
          <div className={styles.copyright}>
            <Typography variant='body2' color='text.hint' sx={{ mb: 0.5 }}>
              Copyright @{new Date().getFullYear()} MyTags
            </Typography>
            <Typography variant='body2' color='text.hint'>
              An INITEQ ♥ BytePanda Product
            </Typography>
          </div>
        </div>
        <nav className={styles.footerMenu}>
          <ul className={styles.menuGroup}>
            <li className={styles.groupHeader}>
              <span className={styles.groupTitle}>
                Product
              </span>
              <Typography variant='body2' color='text.secondary'>
                Learn about our product and how it works for you
              </Typography>
            </li>
            <li className={styles.menuItem}>
              <Link href="/pricing">
                Pricing
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link href="https://mytags.info/t/mytags-examples/17/house-rules-example-embedded-image">
                Tag Example #1
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link href="https://mytags.info/t/mytags-examples/18/house-rules-example-with-rich-content">
                Tag Example #2
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link href="/create-account">
                Sign up
              </Link>
            </li>
          </ul>
          <ul className={styles.menuGroup}>
            <li className={styles.groupHeader}>
              <span className={styles.groupTitle}>
                Company
              </span>
              <Typography variant='body2' color='text.secondary'>
                Learn about who we are and our terms of service
              </Typography>
            </li>
            <li className={styles.menuItem}>
              <Link href="/blog">
                Blog
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link href="/privacy-policy">
                Privacy Policy
              </Link>
            </li>
            <li className={styles.menuItem}>
              <Link href="/terms-of-service">
                Terms of Service
              </Link>
            </li>
          </ul>
          <ul className={styles.menuGroup}>
            <li className={styles.groupHeader}>
              <span className={styles.groupTitle}>
                Contacts
              </span>
              <Typography variant='body2' color='text.secondary'>
                Get in touch with us or follow us on our various channels
              </Typography>
            </li>
            <li className={styles.menuItem}>
              <Link href="/contact-us">
                Contact us
              </Link>
            </li>
          </ul>
        </nav>
      </section>
    </footer>
  )
}
