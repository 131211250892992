import PublicFooter from 'components/navigation/PublicFooter'
import PublicHeader from 'components/navigation/PublicHeader'
import styles from './PublicPageWrapper.module.scss'

export default function PublicPageWrapper ({ children }) {

  return (
    <div className={styles.pageWrapper}>
      <PublicHeader />
      <main>
        {children}
      </main>
      <PublicFooter />
    </div>
  )
}