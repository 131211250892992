import { ArrowRightAltOutlined, CloseOutlined, MenuOpenOutlined } from '@mui/icons-material'
import { Button } from '@mui/material'
import MenuButtonBgBlob from 'components/svg-assets/MenuButtonBgBlob'
import MyTagsLogo from 'components/svg-assets/MyTagsLogo'
import Link from 'next/link'
import React from 'react'
import Flag from 'react-world-flags'
import Context from 'utils/Context'
import styles from './PublicHeader.module.scss'

export default function PublicHeader ({ hideNav = false }) {

  const context = React.useContext(Context);
  const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false);

  return (
    <header className={`${styles.container} ${styles.siteHeader}`}>
      <div className={styles.logoSection}>
        <Link href='/'>
          <span className={styles.logoSpan}>
            <MyTagsLogo />
          </span>
        </Link>
      </div>
      {hideNav ? '' : (
        <nav className={`${mobileMenuOpen && styles.active}`}>
          <ul className={styles.headerNavWrapper}>
            {!context.isAuthenticated && (
              <li className={styles.headerMenuItemCta}>
                <Link href='/create-account' passHref>
                  <Button
                    variant='contained'
                    color='primary'
                    disableElevation
                    startIcon={<ArrowRightAltOutlined />}
                  >
                    Create your Account
                  </Button>
                </Link>
              </li>
            )}
            <li className={styles.headerMenuItem}>
              <Link href='/blog'>
                Blog
              </Link>
            </li>
            <li className={styles.headerMenuItem}>
              <span className={styles.headerMenuSeparator} />
            </li>
            <li className={styles.headerMenuItem}>
              <Link href='/pricing'>
                Pricing
              </Link>
            </li>
            {/* <li className={styles.headerMenuItem}>
              <span className={styles.headerMenuSeparator} />
            </li>
            <li className={styles.headerMenuItem}>
              <Link href='/dashboard'>
                <div className={styles.langSelect}>
                  <Flag
                    code='hr'
                    height='16'
                    alt='Hrvatski'
                  />
                  <span>HR</span>
                </div>
              </Link>
            </li> */}
            <li className={styles.headerMenuItem}>
              <span className={styles.headerMenuSeparator} />
            </li>
            {context.isAuthenticated ? [
              <li key='userDashboardLink' className={styles.headerMenuItem}>
                <Link href='/dashboard'>
                  <div>
                    <span className={styles.linkIntroText}>
                      Hello, {context.userData.get('first_name')}!
                    </span>
                    <div className={styles.itemLabelWithIcon}>
                      <strong>Your Dashboard</strong>
                      <ArrowRightAltOutlined />
                    </div>
                  </div>
                </Link>
              </li>,
              <li key='userMenuSeparator' className={styles.headerMenuItem}>
                <span className={styles.headerMenuSeparator} />
              </li>,
              <li key='userSignout' className={styles.headerMenuItem}>
                <Link href='/auth/sign-out'>
                  Sign out
                </Link>
              </li>
             ] : (
              <li className={styles.headerMenuItem}>
                <Link href='/sign-in'>
                  <div>
                    <span className={styles.linkIntroText}>
                      Existing customer?
                    </span>
                    <strong>Sign in</strong>
                  </div>
                </Link>
              </li>
            )}
          </ul>
        </nav>
      )}
      {hideNav ? '' : (
        <button
          className={`${styles.mobileMenuButton} ${mobileMenuOpen && styles.active}`}
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <MenuButtonBgBlob />
          <div className={styles.menuIconContainer}>
            {mobileMenuOpen ? (
              <CloseOutlined fontSize='large' />
            ) : (
              <MenuOpenOutlined fontSize='large' />
            )}
          </div>
        </button>
      )}
    </header>
  )
}
